/* ./styles/Navbar.css */

/* Custom C1Navbar */
.navbar-custom {
    background-color: rgba(255, 255, 255, 0.90);
    border: none;
    z-index: 999;
    top: 0;
    width: 100%;
    position: fixed;
}

/* Apply hover effect to the nav items */
.navbar-custom .nav-item:hover .nav-link:hover {
    color: rgba(4, 4, 194, 0.637);
}

/* Remove arrow */
.navbar-custom .nav-item .nav-link::after {
    display: none;
}

/* Show dropdown menu on hover */
.nav-item:hover .dropdown-menu {
    display: block;
    background-color: rgba(2, 2, 42, 0.80);
    margin-top: -3px;
}

/* Change dropdown menu items */
.navbar-custom .dropdown-item {
    color: rgba(255, 255, 255, 0.882);
    font-size: 15px; 
}

/* Remove hover effect from dropdown items */
.navbar-custom .dropdown-item:hover {
    color: white;
    background-color: transparent;
}

/* Change submenu items under "Services" */
.navbar-custom .submenu-item {
    padding-left: 35px;
    font-size: 14px; 
    color: rgba(255, 255, 255, 0.85);
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-bottom: -2%;
}
/* ./styles/fonts.css */

@font-face {
    font-family: 'HelveticaLTStd-Roman';
    src: url('~/src/assets/fonts/HelveticaLTStd-Roman.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLTStd-Bd';
    src: url('~/src/assets/fonts/HelveticaNeueLTStd-Bd.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLTStd-Md';
    src: url('~/src/assets/fonts/HelveticaNeueLTStd-Md.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
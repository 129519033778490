/* Footer.css */


.footer-container {
    background-color: rgb(76, 77, 76);
    padding: 20px 0;
    color: white;
    width: 100%;
    height: auto;
}

.footer-content {
    margin-top: 1%;
    margin-bottom: -1.6%;
}

.footer-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align columns to the top */
    margin-bottom: 30px;
}

.footer-column {
    flex-basis: 30%;
}

.footer-column p {
    font-size: 11px;
    margin-bottom: 3px;
}

.footer-logo img {
    max-width: 10%; /* Adjusted logo size */
    margin-left: 89%; /* Push the logo to the right */
    margin-top: -11%;
}

.footer-bottom {
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -2%;
}

.footer-left p {
    font-size: 10px;
    line-height: 0px;
    text-align: left;
    padding-right: 45vw;
    width: 100%;
    margin-left: 0.8%;
}

.footer-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.footer-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.footer-right p {
    font-size: 10px;
    padding-right: 30px;
}
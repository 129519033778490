/* ./styles/Highlights.css */

/* Highlights.css */
.highlights-container {
    background-color: white;
    padding: 50px 0;
    scroll-margin-top: 70px;
}

.highlights-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Bd";
    margin-left: -57%;
}

.highlights-square {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
}

.highlight {
    max-width: 400px;
    min-height: 700px;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px;
    flex-basis: calc(50% - 20px);
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out;
}

.highlight:hover {
    transform: scale(1.02);
    border-color: rgb(24, 32, 81);
}

.highlight img {
    max-width: 100%;
    min-height: 375px;
    height: auto;
}

.highlight-title {
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Md";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 26px;
    min-height: 100px;
}

.highlight-description {
    font-size: 17px;
    margin-bottom: 15px;
    line-height: 28px;
    width: 100%;
    color: rgba(90, 91, 93, 0.89);
    font-family: "HelveticaLTStd-Roman";
    overflow: scroll;
    white-space: pre-wrap;
    text-align: left;
    height: 200px;
}

.react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px)
}

.react-multiple-carousel__arrow--right {
    right: calc(-3% + 100px)
}

.learn-more-link {
    display: inline-block;
    padding: 8px 15px;
    background-color: rgb(24, 32, 81);
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.learn-more-link:hover {
    background-color: rgb(40, 121, 197);
}

@media (max-width: 1060px) {
    .highlights-title {
        text-align: left;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 30px;
        color: rgb(24, 32, 81);
        font-family: "HelveticaNeueLTStd-Bd";
        margin-left: 4%;
    }
  }
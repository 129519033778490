/* ./styles/DPAS.css */

.dpas-section {
    width: 100%;
    height: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10vh 0;
    scroll-margin-top: 0px;
    margin-top: 1%;
}

.dpas-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: relative;
}

.dpas-header {
    margin-left: 5vw;
    margin-bottom: 20px;
    line-height: 14px;
}

.dpas-header h2 {
    font-family: 'HelveticaNeueLTStd-Bd';
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 45px;
    color: rgb(24, 32, 81);
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    height: 60px;
}

.dpas-line {
    margin-top: 21px;
    border: 1.5px solid #00000080;
    width: 90%;
}

.dpas-content p {
    margin-left: 5vw;
    margin-bottom: 4vh;
    font-family: 'HelveticaNeueLTStd-Md';
    text-transform: none;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    color: rgba(90, 91, 93, 0.819);
    z-index: 280;
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 32px;
    width: 1288px;
}

.dpas-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: relative;
}

.dpas-image img {
    max-width: 90%;
    height: auto;
    border-radius: 16px;
    z-index: 1;
}

.image-overlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    height: relative;
    align-items: center;
    color: #ffffff;
    z-index: 2;
}

.image-overlay h3 {
    margin-top: -25%;
    z-index: 2;
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    color: rgb(255, 255, 255);
    width: 100%;
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-line;
    text-align: center;
}

.image-overlay .TCS {
    
}

.image-overlay .DOORS {

}

.image-overlay .Cameo {
   
}

.image-overlay .AFSIM {
   
}

.image-overlay .Experience {
    
}

.image-overlay .Sparx {
   
}

.image-overlay .PCS {
    
}

.image-overlay .MAT {
    
}
@media (max-width: 850px) {
    .dpas-line {
        display: none;
    }
    .dpas-header h2 {
        font-family: 'HelveticaNeueLTStd-Bd';
        text-transform: uppercase;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        color: rgb(24, 32, 81);
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        padding: 0px;
        height: 60px;
        margin-bottom: 5%;
    }
    .dpas-content p {
        margin-left: 5vw;
        margin-bottom: 4vh;
        font-family: 'HelveticaNeueLTStd-Md';
        text-transform: none;
        font-weight: 300;
        font-style: normal;
        font-size: 18px;
        color: rgba(90, 91, 93, 0.819);
        z-index: 280;
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        padding: 0px;
        line-height: 32px;
        width: 80%;
    }
  }
.stories-background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: relative;
    background-color: white;
    scroll-margin-top: 90px;
}

.stories-title {
    margin-left: 5%;
    font-size: 45px;
    font-family: 'HelveticaNeueLTStd-Bd';
    text-transform: uppercase;
    color: rgb(24, 32, 81);
}

.videoMask {
    border-radius: 10px;
    overflow: hidden;
  }

.stories-subsection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: relative;
}

/* button stuff */

.stories-buttons-group {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: relative;
    justify-content: center;
    align-items: center;
    margin: 2.5% 5% 0% 5%;
}

.stories-button {
    width: 135%;
    height: 100px;
    border-radius: 50px;
    border-width: 1px;
    border-color: rgb(131, 131, 131);
    margin-bottom: 35px; 
}

.button-select {
    border-color: rgb(24, 32, 81);
    background-color: rgb(24, 32, 81);
}

.button-select:hover {
    background-color: rgb(24, 32, 81);
}

.button-deselect {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(24, 32, 81);
}

.button-deselect:hover {
    border-color: rgb(131, 131, 131);
    background-color: rgba(0, 0, 0, 0);
    color: rgb(24, 32, 81);
}

.circle-title {
    display: flex;
    flex-direction: row;
    margin-left: 7%;
    height: relative;
    align-items: center;
}

.circle-title h3 {
    margin-bottom: 0%;
    font-size: 20px;
    font-family: 'HelveticaNeueLTStd-Bd';
    line-height: relative;
    width: 75%;
    text-align: left;
    margin-left: -3%;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    font-family: 'HelveticaNeueLTStd-Bd';
    margin: 0 20px;
}

.circle-select {
    background-color: rgb(40, 121, 197);
    color:rgb(24, 32, 81);
}

.circle-deselect {
    background-color: rgb(24, 32, 81);
    color: white;
}

/* videos and captions */

.stories-videos-captions {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100vh;
    margin-top: 1.25%;
    margin-left: 2%;
    align-items: center;
    border-top-left-radius: 25px;
    background-color: rgb(245, 245, 245);
}

/* videos */

.stories-videos-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 73%;
    width: 80%;
    margin-top: 5%;
}

.stories-video {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 20px 50px 0px;
}

/* .AUvideo {
    background-image: url('~/src/assets/images/Thumbnail_AU.jpg');
}

.ELICSARvideo {
    background-image: url('~/src/assets/images/Thumbnail_ELICSAR.jpg');
}

.AIMvideo {
    background-image: url('~/src/assets/images/thumbnail-aim-high.jpg');
} */

/* captions */

.stories-captions-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25%;
    width: 90%;
    margin-top: 4%;
}

.stories-caption {
    width: 100%;
    height: 100%;
}

.stories-caption h3 {
    font-size: 25px;
    font-family: 'HelveticaNeueLTStd-Bd';
    color: rgb(62, 105, 189);
}

.stories-bar-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: relative;
    width: 100%;
}

.stories-bar-text img {
    margin-right: 15px;
    margin-left: 1px;
    width: 7px;
    height: 100%;
}

.stories-bar-text p {
    display: flex;
    align-items: center;
    width: 100%;
    height: relative;
    margin: auto 0px;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    font-family: 'HelveticaLTStd-Roman';
    color: rgb(90, 91, 93);
    text-align: left;
    overflow: visible;
}

@media (max-width: 750px) {
    .stories-videos-captions {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 100%;
        margin-top: 1.25%;
        margin-left: 2%;
        align-items: center;
        border-top-left-radius: 25px;
        background-color: rgb(245, 245, 245);
        margin-bottom: 45%;
    }
    .circle {
        display: none;
    }
    .circle-title h3 {
        margin-bottom: 0%;
        font-size: 15px;
        font-family: 'HelveticaNeueLTStd-Bd';
        line-height: relative;
        width: 75%;
        text-align: center;
        margin-left: -3%;
    }
}
/* ./styles/QuickTips.css */

.quicktips-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 0;
}


.quicktips-section {
    margin-top: -2vh;
    margin-left: -7.8vw;
    background-color: #ffffff;
    padding: 40px 0;
    scroll-margin-top: 90px;
}
  
  .quicktips-header {
    margin-bottom: 40px;
    text-align: left;
    margin-left: 5%;
}

.quicktips-link {
    font-weight: bold;
    color: #000049;
}
  
  .quicktips-header h2 {
    font-weight: bold;
    color: #000049;
    font-size: 45px;
    font-family: 'HelveticaNeueLTStd-Bd';
} 

.q-icon {
  margin-top: 25px;
}

.q-icon img {
  width: 60px;
  height: auto;
}

.q-icon p {
  margin-top: -6vh;
  margin-left: 5vw;
  font-size: 21px;
  font-family: 'HelveticaNeueLTStd-Md';
  color: rgb(62, 105, 189);
  font-weight: 400;
  font-style: normal;
}

.q-paragraph {
  text-align: left;
  margin-top: -1vh;
  margin-left: 5vw;
  width: 100%;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: rgb(90, 91, 93);
  font-family: 'HelveticaLTStd-Roman';
}  

.gov-link {
  /* margin-left: 5.1vw;
  margin-top: -10vh;
  text-align: left; */
  font-family: 'HelveticaNeueLTStd-Bd';
}

.gov-link a {
  text-decoration: none;
  color:rgb(69, 114, 203); 
  font-size: 15px;
}

.gov-link a:hover {
  text-decoration: none;
}

.gov-link .bar-icon {
  width: 6px;
  height: 17px;
  border-radius: 8px;
  margin-left: -1vw;
  margin-right: 10px;
  margin-top: 0.5vh;
}

@media (max-width: 1125px) {
  .q-icon img {
    width: 5%;
    height: auto;
    /* padding-block: initial; */
    position: relative;
}
.q-icon p {
  margin-top: -4%;
  margin-left: 6%;
  font-size: 21px;
  font-family: 'HelveticaNeueLTStd-Md';
  color: rgb(62, 105, 189);
  font-weight: 400;
  font-style: normal;
}
}
@media (max-width: 580px) {
  .q-paragraph {
    text-align: left;
    margin-top: -1vh;
    margin-left: 7%;
    width: 365px;
    height: 100px;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: rgb(90, 91, 93);
    font-family: 'HelveticaLTStd-Roman';
}
}
/* ./styles/Home.css */

.home-container {
    position: relative;
    margin-top: -8vh;
    top: 0;
    left: 0;
    width: 100%;
    height: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.fade-in-animation {
    opacity: 0;
    transition: opacity 1s ease; /* Smooth transition for opacity */
}
  
.fade-in-animation.visible {
    opacity: 1;
}

.drop-in-animation {
    opacity: 0;
    transform: translateY(-30px); /* Move the element up by 50px */
    transition: opacity 1s ease, transform 1s ease;
}

.drop-in-animation .p2 {
    opacity: 0;
    transform: translateY(-50px); /* Move the element up by 50px */
    transition: opacity 1s ease, transform 1s ease;
}
  
/* When the element is visible, remove the translation and set opacity to 1 */
.drop-in-animation.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Blue Pilot Home BG */
.background-image {
    position: relative;
    background-image: url('~/src/assets/images/Background_home.png'); /* gives us the image */
    background-attachment: scroll; /* image will scroll with page */
    background-position: center; /* centers image */
    background-size: cover;
    width: 100%;
    height: 110vh;
}

/* Full text overlay (Take mission to cloud -> Production badge)*/
.text-overlay {
    position: relative;
    top: 10vh;
    left: 4vw;
    width: 50%;
    z-index: 1;
}

/* Take your mission to the cloud */
.text-overlay img {
    margin-left: -0.5vw;
    width: 90%; 
    margin-top: 1vh;
    margin-bottom: -3.5vh;
}

/* Overlay of TYMTTC text*/
.text-overlay .p1 {
    margin-bottom: 4.5vh;
    margin-left: 3vw;
    font-weight: 400;
    font-size: 22px;
    font-family: "HelveticaLTStd-Roman";
    width: 115%;
    color: lightgrey;
    display: flex; 
    flex-direction: column;
    overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Badges Overall Div */
.badges {
    display: flex;
    width: 100%;
}

/* Badges img */
.text-overlay div img {
    margin-left: 3vw;
    margin-right: 8vw;
    margin-top: 1vh;
    width: 21%;
}

/* Overlay of Badges Titles */
.badges .p1 {
    margin-top: 5vh;
    margin-left: 3vw;
    font-weight: 400;
    font-size: 20px;
    font-family: "HelveticaLTStd-Roman";
    width: 120%;
    color: #ffffff;
    display: flex; 
    flex-direction: column;
}

/* Overlay of Badges Stats */
.badges .p2 {
    margin-top: -4.5vh;
    margin-left: 3vw;
    font-weight: 400;
    margin-bottom: 22vh;
    font-size: 14px;
    font-family: "HelveticaLTStd-Roman";
    width: 120%;
    color: rgb(185, 185, 185);
    display: flex; 
    flex-direction: column;
}

/* Move your mission app to cloud one */
.ma-background {
    align-items: center; 
    justify-content: center;
    display: flex; 
    flex-direction: column;
    background-color: rgb(8, 8, 60);
    background-attachment: scroll; /* image will scroll with page */
    background-position: center; /* centers image */
    background-size: cover;
    width: 100%;
    height: 500px;
    padding: 0%;
}

/* Move your mission app to cloud one text*/
.ma-background .p1 {
    margin-top: -8vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-size: 35px;
    font-weight: 400;
    font-family: "HelveticaNeueLTStd-Bd";
}

/* Table Image + outside group of icons and words */
.ma-table {
    margin-top: -4vh;
    background-image: url('~/src/assets/images/Icon_mission-app_table.png'); /* gives us the image */
    background-attachment: scroll; /* image will scroll with page */
    background-position: center; /* centers image */
    background-size: 103% 115%; /* to get rid of the annoying little margin in the original image*/
    background-repeat: no-repeat;
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ma-icon-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.ma-icon {
    background-attachment: scroll; /* image will scroll with page */
    background-position: center; /* centers image */
    background-repeat: no-repeat;
    margin-top: 0%;
    width: 25%;
    height: 100%;
    scale: 55%;
}

.cost {
    background-image: url('~/src/assets/images/Icon_mission-app_cost.png');
}

.scal {
    background-image: url('~/src/assets/images/Icon_mission-app_scaling.png');
}

.perf {
    background-image: url('~/src/assets/images/Icon_mission-app_performance.png');
}

.ato {
    background-image: url('~/src/assets/images/Icon_mission-app_ato.png');
}

.ma-word-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40%;
}

.ma-word-group div {
    margin-top: -3%;
    display: flex;
    justify-content: center;
    width: 25%;
    height: 100%;
}

.ma-word-group div p {
    color: rgb(215, 215, 215);
    text-align: center;
    font-size: 20px;
    width: 69%;
}

/* Unleashing the power of the cloud BG */
.unleash {
    position: relative;
}

.unleash .UnleashBG{
    background-attachment: scroll;
    background-position: center; 
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

/* Unleash Text Overlay */
.unleash .UnleashTxtOverlay{
    /* position: absolute;
    margin-top: -55vh;
    margin-left: -3%;
    scale: 65%; */
    position: absolute;
    margin-top: -55vh;
    margin-left: -3%;
    scale: 64%;
    /* position: absolute; */
    margin-top: -58vh;
    margin-left: -0.2vw;
    /* z-index: 2; */
    /* color: rgb(88, 88, 88); */
    /* font-size: 20px; */
    width: 54.2%;
}

/* Unleash Text Paragraph */
.unleash p {
    position: absolute;
    margin-top: -35vh;
    margin-left: 9.8vw;
    z-index: 2;
    color: rgb(88, 88, 88);
    font-size: 20px;
    width: 54.2%;
    font-family: "HelveticaNeueLTStd-Md";
}

/* Leading the Way and Reliable Partnership */
.LTW-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
}

/* Blue Rectangle for LTW */
.LTW-section .blue-boxhome {
    display: flex;
    align-items: flex-end;
    background-color: rgb(25, 34, 81);
    width: 100%;
    height: 70px;
    margin-left: 0%;
    margin-top: 2%;
}

/* LTW & RP Headers */
.LTW-section h3 {
    text-transform: uppercase;
    font-family: "HelveticaNeueLTStd-Md";
    font-weight: 600;
    font-size: 35px;
    margin-left: 7vw;
}

/* Overall box for LTW & RP content */
.content {
    display: flex;
    flex-direction: row;
    margin-left: 4.8vw;
    margin-top: 6vh;
    height: 70%;
}

/* Both Images */
.content img {
    margin-top: -10%;
    margin-left: -5vw;
    height: 68vh;
    scale: 65%;
}

/* Shape around the Bars */
.content .Bar {
    display: flex; 
    flex-direction: row;
    align-items: center;
    min-height: 65%;
    width: 90%;
    margin-right: 7%;
}

/* Bar Images */
.content .Bar img {
    margin-top: -2vh;
    margin-right: 15px;
    width: 1.5%;
    border-radius: 5px;
}

/* Paragraphs to right of the Bars */
.content .Bar p {
    color: rgb(90, 91, 93);
    font-family: "HelveticaNeueLTStd-Md";
    font-weight: 400;
    font-size: 20px;
    align-content: flex-start;
    width: 855px;
    height: 75%;
    margin-right: -2vw;
    overflow-wrap: break-word;
}


/* Smaller screens */

@media (max-width: 900px) {
    .unleash .UnleashTxtOverlay {
        margin-top: -55%;
    margin-left: -7vw;
    font-weight: 400;
    font-size: 9px;
    z-index: 2;
    width: 90%;
    display: flex;
    flex-direction: column;
    }
    /* Unleash paragraph*/
    /* .unleash p {
        margin-top: 12vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 13px;
        z-index: 2;
        color: rgb(88, 88, 88);
        width: 90%;
        display: flex;
        flex-direction: column;
    } */
}

@media (max-width: 768px) {
    /* Blue Pilot Home BG */
    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70vh;
        object-fit: cover;
        background-size: cover;
        z-index: 1;
    }
    /* Full text overlay (Take mission to cloud -> Production badge)*/
    .text-overlay {
        position: absolute;
        align-items: flex-start;
        top: 10vh;
        left: 5vw;
        z-index: 1;
    }
    /* Take your mission to the cloud */
    .text-overlay img {
        width: 150%;
        margin-left: -3.5vw;
        margin-bottom: 4.5vh; 
    }
    /* Overlay of TYMTTC text*/
    .text-overlay .p1 {
        margin-top: -6vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 13px;
        font-family: "HelveticaLTStd-Roman";
        width: 130%;
        color: lightgrey;
        display: flex; 
        flex-direction: column;
    }
    /* Badges */
    .text-overlay div img {
        margin-top: -2vh;
        margin-left: 3vw;
        margin-right: 8vw;
        width: 20%;
    }
    /* Overlay of Badges Titles */
    .text-overlay .badges p {
        margin-top: -3.5vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 10px;
        font-family: "HelveticaLTStd-Roman";
        width: 120%;
        color: #ffffff;
        display: flex; 
        flex-direction: column;
    }

    /* Overlay of Badges Stats */
    .text-overlay .badges .p2 {
        margin-top: -4.5vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 10px;
        font-family: "HelveticaLTStd-Roman";
        width: 120%;
        color: rgb(185, 185, 185);
        display: flex; 
        flex-direction: column;
    }
    /* Move your mission app to cloud one */
    .ma-background {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        background-color: rgb(8, 8, 60);
        background-repeat: no-repeat;
        object-fit: cover;
        background-size: cover;
        margin-top: 60vh;
        height: 60vh;
        max-width: 100%;
        width: 100%;
    }
    /* Move your mission app to cloud one text*/
    .ma-background .p1 {
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 30%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: white;
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        font-family: "HelveticaNeueLTStd-Bd";
    }

    /* Table Image + outside group of icons and words */
    .ma-table {
        margin-top: -4vh;
        background-image: url('~/src/assets/images/Icon_mission-app_table.png'); /* gives us the image */
        background-attachment: scroll; /* image will scroll with page */
        background-position: center; /* centers image */
        background-size: 103% 115%; /* to get rid of the annoying little margin in the original image*/
        background-repeat: no-repeat;
        width: 90%;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ma-icon-group {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 60%;
    }

    .ma-icon {
        background-attachment: scroll; /* image will scroll with page */
        background-position: center; /* centers image */
        background-repeat: no-repeat;
        margin-top: 1.5%;
        width: 25%;
        height: 100%;
        scale: 40%;
    }

    .cost {
        background-image: url('~/src/assets/images/Icon_mission-app_cost.png');
    }

    .scal {
        background-image: url('~/src/assets/images/Icon_mission-app_scaling.png');
    }

    .perf {
        background-image: url('~/src/assets/images/Icon_mission-app_performance.png');
    }

    .ato {
        background-image: url('~/src/assets/images/Icon_mission-app_ato.png');
    }

    .ma-word-group div {
        display: flex;
        justify-content: center;
        width: 25%;
        height: 100%;
    }

    .ma-word-group div p {
        color: rgb(185, 185, 185);
        text-align: center;
        font-size: 9px;
        width: 80%;
    }
    /* Unleashing the power of the cloud */
    .unleash {
        background-attachment: scroll; /* image will scroll with page */
        background-position: center; /* centers image */
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    /* Unleash Text-Overlay*/
    .unleash img{
       position: relative;
        z-index: 2;
        /* margin-top: 5vh; */
        /* margin-left: 8vw; */
        width: 100%;
        background-size: cover;
    }
    /* Unleash Text Overlay */
    .unleash .UnleashTxtOverlay {
        margin-top: -30%;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 9px;
        z-index: 2;
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    /* Unleash paragraph*/
    .unleash p {
        margin-top: 12vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 13px;
        z-index: 2;
        color: rgb(88, 88, 88);
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .content .Bar p {
        color: rgb(90, 91, 93);
        font-family: "HelveticaNeueLTStd-Md";
        font-weight: 400;
        font-size: 13px;
        align-content: flex-start;
        width: 855px;
        height: 90%;
        margin-right: -2vw;
        overflow-wrap: break-word;
    }

    .content .Bar img {
        display: none;
    }

    .content img {
       display: none;
    }
    
}

@media (max-width: 500px) {
    .text-overlay .p1 {
        margin-top: -6vh;
        margin-left: 3vw;
        font-weight: 400;
        font-size: 13px;
        font-family: "HelveticaLTStd-Roman";
        width: 130%;
        color: lightgrey;
        display: flex;
        flex-direction: column;
    }
}
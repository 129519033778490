/* ./styles/Contact.css */

.contact-section {
    background: linear-gradient(0deg, rgb(21, 32, 78) 0%, rgb(26, 41, 104) 100%);
    color: white;
    text-align: center;
    padding: 50px 0;
    /* height: 80vh; */
    scroll-margin-top: 90px;
}

.contact-header {
    margin-left: 6.6%;
}

.contact-title {
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    color: rgb(255, 255, 255);
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 12px;
    margin-top: 3%;
}

.contact-description {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 26px;
    width: 79%;
    padding-top: 2%;
    padding-bottom: 0.5%;
}

.contact-buttons {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 2%;
}

.contact-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    background-color: rgb(12, 19, 52);
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    border-width: 0px;
    border-radius: 14px;
    width: 480px;
    height: 265px;
}

.button1-text {
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-line;
    text-align: center;
    padding: 0px;
    line-height: 25px;
    width: 65%;
}

.button2-text {
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-line;
    text-align: center;
    padding: 0px;
    line-height: 25px;
    width: 75%;
}

.contact-button:hover {
    background-color: rgb(19, 61, 132);
}

.button-icon {
    scale: 75%;
    margin-bottom: 5px;
}

@media (max-width: 440px) {
    .contact-title {
        text-transform: uppercase;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        color: rgb(255, 255, 255);
        font-family: "HelveticaNeueLTStd-Bd";
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        padding: 0px;
        line-height: 12px;
        margin-top: 3%;
    }
    .contact-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 2%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
    .contact-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px 20px;
        background-color: rgb(12, 19, 52);
        color: white;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s;
        border-width: 0px;
        border-radius: 14px;
        width: 480px;
        height: 100%;
    }
    .contact-section {
        background: linear-gradient(0deg, rgb(21, 32, 78) 0%, rgb(26, 41, 104) 100%);
        color: white;
        text-align: center;
        padding: 50px 0;
        height: 100%;
        scroll-margin-top: 90px;
    }
  }

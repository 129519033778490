/* ./styles/Partnerships.css */

.partnerships-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: rgb(245, 245, 245);
    padding: 20px;
    text-align: center;
    height: 100%;
    width: 100%;
    scroll-margin-top: 40px;
}

.partnerships-header {
    margin-top: 3%;
    margin-left: 5%;
}

.section-title1 {
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 33px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 12px;
}

.sub-title1 {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 29px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding-top: 4%;
    line-height: 26px;
}

.partners-text {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: rgb(90, 91, 93);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding-top: 0.5%;
    line-height: 25px;
}

.partner-logo {
    width: 90%;
    height: auto;
    margin: 0 10px;
    filter: grayscale(0%);
    /* transition: opacity 0.3s ease-in-out; */
    z-index: 2;
    margin-left: 5%;
    margin-right: 5%;
    cursor: "pointer";
}

.partner-logo.active {
    opacity: 1;
}

.partner-logo.always-visible {
    opacity: 1;
    z-index: 1;
}

.modal-content {
    background-color: rgba(24, 32, 81, 0.95);
    color: white;
    padding: 4%;
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    /* color: white; */
    background-color: white;
}

.modal-footer {
    /* display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg); */
    justify-content: flex-start;
     border-top: none;
    /* border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius); */
}

.partner-block:hover {
    background-color: rgba(24, 32, 81, 0.95);
    cursor: "pointer";
    transform: scale(1.03);
}

.hotspot {
    position: absolute;
    width: 340px;
    height: 230px;
    background-color: transparent;
    border: 10px solid transparent; /* Add red border to visualize hotspot */
    cursor: wait;
    pointer-events: auto;
    z-index: 5;
    margin-top: 98.5%;
  }
/* ./styles/Policy.css */

.policy-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.policy-section {
    margin-top: -2vh;
    margin-left: -7.8vw;
    background-color: #ffffff;
    padding: 40px 0;
    scroll-margin-top: 90px;
}
  
  .policy-header {
    margin-bottom: 40px;
}
  
.policy-header h2 {
  font-weight: bold;
  color: #000049;
  margin-left: -13%;
  margin-left: 10%;
  font-size: 45px;
  font-family: 'HelveticaNeueLTStd-Bd';
}
  
  .policy-line1 {
    margin-top: 10px;
    border: 1px solid #00000080;
    width: 40vw;
}

  .policy-line2 {
    /* margin-top: 10px; */
    border: 1px solid #00000080;
    width: 40vw;
}
  
  .directives-icon {
    margin-top: 25px;
}
  
  .directives-icon img {
    width: 60px;
    height: auto;
}
  
  .directives-icon p {
    margin-top: -4vh;
    margin-left: 5vw;
    font-size: 21px;
    font-family: 'HelveticaNeueLTStd-Md';
    color: rgb(62, 105, 189);
    font-weight: 400;
    font-style: normal;
   
}
  
  .directives-paragraph {
    text-align: left;
    margin-top: -4vh;
    margin-left: 5vw;
    width: 100%;
    height: 100px;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: rgb(90, 91, 93);
    font-family: 'HelveticaLTStd-Roman';
    overflow-wrap: break-word;
    width: 80%;
}  

.governance-icon {
  margin-top: 25px;
}

.governance-icon img {
  width: 60px;
  height: auto;
}

.governance-icon p {
  margin-top: -6vh;
  margin-left: 5vw;
  font-size: 21px;
  font-family: 'HelveticaNeueLTStd-Md';
  color: rgb(62, 105, 189);
  font-weight: 400;
  font-style: normal;
  width: 50%;
  overflow-wrap: break-word;
}

.governance-paragraph {
  text-align: left;
  margin-top: -1vh;
  margin-left: 5vw;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: rgb(90, 91, 93);
  font-family: 'HelveticaLTStd-Roman';
  width: 80%;
  overflow-wrap: break-word;
}  

.governance-end {
  text-align: left;
  margin-top: -3%;
  margin-left: 5vw;
  width: 600px;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  color: rgb(95, 95, 93);
  font-family: 'HelveticaNeueLTStd-Bd';
}

.gov-link {
  margin-left: 5.1vw;
  margin-top: -10vh;
  text-align: left;
  font-family: 'HelveticaNeueLTStd-Bd';
}

.gov-link a {
  text-decoration: none;
  color:rgb(69, 114, 203); 
  font-size: 15px;
}

.gov-link a:hover {
  text-decoration: none;
}

.gov-link .bar-icon {
  width: 6px;
  height: 17px;
  border-radius: 8px;
  margin-left: -1vw;
  margin-right: 10px;
  margin-top: 0.5vh;
}

@media (max-width: 1200px) {
  .policy-line1 {
    display: none;
}

  .policy-line2 {
    display: none;
}

.governance-icon p {
  margin-top: 14%;
  margin-left: 5vw;
  font-size: 21px;
  font-family: 'HelveticaNeueLTStd-Md';
  color: rgb(62, 105, 189);
  font-weight: 400;
  font-style: normal;
  width: 50%;
  overflow-wrap: break-word;
}

.directives-paragraph {
  text-align: left;
  margin-top: -4vh;
  margin-left: 5vw;
  width: 100%;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: rgb(90, 91, 93);
  font-family: 'HelveticaLTStd-Roman';
  overflow-wrap: break-word;
  width: 100%;
} 
  
}

@media (max-width: 760px) {
  .directives-icon {
    margin-top: 10%;
    margin-left: 4%;
  }

  .governance-icon {
    margin-top: 10%;
    margin-left: 4%;
  }

  .directives-paragraph {
    text-align: left;
    margin-top: -4vh;
    margin-left: 8%;
    width: 100%;
    height: 100px;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: rgb(90, 91, 93);
    font-family: 'HelveticaLTStd-Roman';
    overflow-wrap: break-word;
    width: 90%;
}
.governance-paragraph {
  text-align: left;
  margin-top: -1vh;
  margin-left: 8%;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: rgb(90, 91, 93);
  font-family: 'HelveticaLTStd-Roman';
  width: 90%;
  overflow-wrap: break-word;
}

.governance-end {
  text-align: left;
  margin-top: -3%;
  margin-left: 8%;
  width: 90%;
  height: 100px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  color: rgb(95, 95, 93);
  font-family: 'HelveticaNeueLTStd-Bd';
}

.gov-link {
  margin-left: 8%;
  margin-top: -10vh;
  text-align: left;
  font-family: 'HelveticaNeueLTStd-Bd';
}
}
/* ./styles/Slideshow.css */

.slideshow-container {
    max-width: 800px;
    margin: 0 auto;
    position: absolute;
    margin-top: 3%; 
    margin-left: 5%; 
    margin-right: auto;
}

.slideshow-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 5;
}

.slideshow-arrows button {
    background-color: transparent; /* Represents the button hotspot, will be an arrow svg over them */
    border: none;
    padding: 0; 
    width: 81px; /* Set arrow hotspot width */
    height: 340px; /* Set arrow hotspot height */
    font-size: 0;
    cursor: pointer;
    transition: none;
    border-radius: 14px;
    z-index: 10;
}

.prev-arrow {
    margin-left: -6.4vw;
}

.next-arrow {
    margin-right: -2.9vw;
}

.slideshow {
    display: flex;
    overflow: hidden;
    background-image: linear-gradient(270deg, rgb(53, 81, 170) 0%, rgb(82, 185, 242) 100%); /* Gradient background */
    align-items: center;
    width: 670px;
    height: 340px;
    border-radius: 14px;
    margin-left: -4%;
}

.slide {
    position: absolute;
    width: 250px;
    height: 360px;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    margin-top: -10vh;
    margin-left: 2vw;
    z-index: 10;
    border-radius: 20px;
}

.slide.active {
    opacity: 1;
}

.slide-content {
    padding: 20px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 8;
}

.slide h3{
    position: absolute;
    margin-left: 16.5vw;
    margin-top: 8vh;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    width: 344px;
}

.slide p {
    position: relative;
    margin-left: 16.5vw;
    margin-top: 22vh;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    text-align: left;
    line-height: 19px;
    width: 240px;
    height: 81px;
}

.slideshow-dots {
    position: absolute;
    margin-top: 54vh;
    right: 1.5vw;
    display: flex;
    z-index: 1;
}

.dot {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #36ace3b7;
}

@media (max-width: 1200px) {
    .slideshow-container {
        display: none;
    }
    
}
/* ./styles/Leadership.css */

.leadership-section {
    background-color: rgb(245, 245, 245);
    padding: 50px 0;
    text-align: center;
    height: 100%;
    margin-top: 10%;
}
  
.leadership-header {
    margin-top: -7%;
    margin-bottom: 30px;
    margin-left: 6.5%;
}

.section-title {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 26px;
  }
  
  .sub-title {
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    color: rgb(90, 91, 93);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 25px;
  }


.all-circles {
    margin-top: 3%;
    margin-left: 16%;
    display: flex;
    flex-direction: column;
    align-items: left;
}
  
.circle-group-1,
.circle-group-2 {
    /* display: flex; */
    align-items: center;
  }
  
  .circle-container {
    display: flex;
    align-items: center;
    margin-left: 10%;
}
  
  .Lead-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 20px;
  }
  
  .light-blue {
    background-color: rgb(157, 213, 245);
    color: white;
  }
  
  .dark-grey {
    background-color: rgba(169, 169, 169, 0.503);
    color: white;
  }
  
  .initials {
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    color: rgb(24, 32, 81);
    font-family: "HelveticaNeueLTStd-Bd";
    overflow: visible;
    white-space: pre-line;
    text-align: center;
    padding: 0px;
    line-height: 26px;
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .info-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 3%;
    margin-left: -4%;
  }

  .name {
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    color: rgb(62, 105, 189);
    font-family: "HelveticaNeueLTStd-Md";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 29px;
    margin-bottom: 1%;
  }
  
  .work-title {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: rgb(90, 91, 93);
    font-family: "HelveticaNeueLTStd-Md";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 17px;
    margin-right: 5%;
  }
  
  .title-end {
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: rgba(90, 91, 93, 0.807);
    font-family: "HelveticaNeueLTStd-Md";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 17px;
  }

  @media (max-width: 800px) {
    .all-circles {
      margin-top: 3%;
      margin-left: 0%;
      display: flex;
      flex-direction: column;
      align-items: left;
  }
  .name {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: rgb(62, 105, 189);
    font-family: "HelveticaNeueLTStd-Md";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 29px;
    margin-bottom: 1%;
}
.work-title {
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  color: rgb(90, 91, 93);
  font-family: "HelveticaNeueLTStd-Md";
  overflow: visible;
  white-space: pre-wrap;
  text-align: left;
  padding: 0px;
  line-height: 17px;
}
}

@media (max-width: 490px) {
  .Lead-circle {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 20px;
}
.all-circles {
  margin-top: 3%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
}

  
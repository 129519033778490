/* ./styles/Benefits.css */

.benefits-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 9%;
    background-image: url('~/src/assets/images/Background_benefits.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: -50px;
}
.benefits-mobile {
    display: none;
}
.header-image {
    width: 18%;
    margin-top: 9vh;
    margin-left: 10%;
}

.text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
}

.left-text {
    margin-left: 4vw;
    margin-top: 6vh;
    flex-direction: column;
    text-align: right;
    font-family: 'HelveticaNeueLTStd-Md';
    z-index: 3;
}

.left-text h4 {
    color: white;
    font-style: bold;
    padding-bottom: 1vh;
    font-size: 21px;
    font-weight: 500;
}

.left-text p {
    color: rgb(211, 215, 220);
    padding-bottom: 8vh;
    font-size: 14px;
    font-weight: 200;
    width: 380px;
}

.right-text {
    margin-left: 70%;
    margin-top: 0%;
    flex-direction: column;
    text-align: left;
    font-family: 'HelveticaNeueLTStd-Md';
    z-index: 3;
    position: relative;
}

.right-text h4 {
    color: white;
    font-style: bold;
    padding-bottom:1vh;
    font-size: 21px;
    font-weight: 500;
    width: 450px;
}

.right-text p {
    color: rgb(211, 215, 220);
    padding-bottom: 5svh;
    font-size: 14px;
    font-weight: 200;
    width: 450px;
}

.center-text {
    margin-top: 130%;
    margin-left: 50%;
    flex-direction: column;
    text-align: center;
    font-family: 'HelveticaNeueLTStd-Md';
    z-index: 3;
}

.center-text h4 {
    color: white;
    font-style: bold;
    padding-bottom: 0vh;
    font-size: 21px;
    font-weight: 500;
}

.center-text p {
    color: rgb(211, 215, 220);
    padding-bottom: 5vh;
    font-size: 14px;
    font-weight: 200;
    width: 450px;
}


@media (max-width: 1560px) {
    .right-text {
        margin-left: 40%;
        margin-top: 4%;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
    .center-text {
        margin-top: 120%;
        margin-left: 30%;
        flex-direction: column;
        text-align: center;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
}

@media (max-width: 1400px) {
    .right-text {
        margin-left: 20%;
        margin-top: 5%;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
    .center-text {
        margin-top: 110%;
        margin-left: 15%;
        flex-direction: column;
        text-align: center;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
}

@media (max-width: 1290px) {
    .right-text {
        margin-left: 3%;
        margin-top: -5%;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }

    .center-text {
        margin-top: 100%;
        margin-left: 15%;
        flex-direction: column;
        text-align: center;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
}

@media (max-width: 1290px) {
    .benefits-full {
        display: none;
    }
    .benefits-mobile {
        display: flex;
        margin-left: 5%;
    }

    .benefits-mobile h1 {
        font-weight: bold;
        color: #000049;
        font-size: 45px;
        font-family: 'HelveticaNeueLTStd-Bd';
    }
    .benefits-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 9%;
        background-image: none;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position-y: -50px;
    }

    .header-image {
        width: 60%;
        margin-top: 1%;
        margin-left: 5%;
        margin-right: 10%;
    }
    .text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
    }
    
    .left-text {
        margin-left: 4vw;
        margin-top: 6vh;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
    
    .left-text h4 {
        color: black;
        font-style: bold;
        padding-bottom: 1vh;
        font-size: 21px;
        font-weight: 500;
        text-align: left;
        flex-direction: column;
    }
    
    .left-text p {
        color: rgb(2, 2, 2);
        padding-bottom: 8vh;
        font-size: 14px;
        font-weight: 200;
        width: 380px;
        flex-direction: column;
        text-align: left;
    }
    
    .right-text {
        margin-left: -170%;
        margin-top: 150%;
        flex-direction: column;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
    
    .right-text h4 {
        color: black;
        font-style: bold;
        padding-bottom:1vh;
        font-size: 21px;
        font-weight: 500;
        text-align: left;
        flex-direction: column;
    }
    
    .right-text p {
        color: rgb(6, 6, 6);
        padding-bottom: 5vh;
        font-size: 14px;
        font-weight: 200;
        width: 380px;
        text-align: left;
        flex-direction: column;
    }
    
    .center-text {
        margin-top: 150%;
        margin-left: -100%;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }
    
    .center-text h4 {
        text-align: left;
        color: black;
        font-style: bold;
        padding-bottom: 0vh;
        font-size: 21px;
        font-weight: 500;
        flex-direction: column;
    }
    
    .center-text p {
        color: rgb(0, 0, 0);
        padding-bottom: 5vh;
        font-size: 14px;
        font-weight: 200;
        width: 380px;
        text-align: left;
        flex-direction: column;
    }
}

@media (max-width: 760px) {
    left-text {
        display: flex;
        font-size: 13px;
        margin-right: 10%;
        margin-left: 4%;
        margin-top: 5%;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
        overflow-wrap: break-word;
    }

    .left-text p {
        color: rgb(2, 2, 2);
        padding-bottom: 8vh;
        font-size: 14px;
        font-weight: 200;
        width: 360px;
        flex-direction: column;
        text-align: left;
    }

    .left-text h4 {
        color: black;
        font-style: bold;
        padding-bottom: 1vh;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        text-align: left;
        flex-direction: column;
    }

    .center-text {
        margin-bottom: 0%;
        margin-top: 155%;
        margin-left: -95%;
        flex-direction: column;
        text-align: left;
        font-size: 13px;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }

    .center-text h4 {
        text-align: left;
        color: black;
        font-style: bold;
        padding-bottom: 0vh;
        font-size: 18px;
        font-weight: 500;
        flex-direction: column;
    }

    .right-text {
        margin-left: -165%;
        margin-top: 155%;
        flex-direction: column;
        flex-direction: column;
        text-align: left;
        font-family: 'HelveticaNeueLTStd-Md';
        z-index: 3;
    }

    .right-text h4 {
        color: black;
        font-style: bold;
        padding-bottom: 1vh;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        flex-direction: column;
    }

    .right-text p {
        color: rgb(6, 6, 6);
        padding-bottom: 5vh;
        font-size: 13px;
        font-weight: 200;
        width: 360px;
        text-align: left;
        flex-direction: column;
    }
}


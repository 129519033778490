/* ./styles/Services.css */

.services-section {
    width: 100%;
    height: relative;
    background-color: #ffffff;
}

.flip-card {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 500px;
    /* background-color: rgb(23, 35, 94); */
    border-radius: 25px;
    padding: 20px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    width: 90%;
    margin-top: 3%;
    text-align: center;
    color: rgb(211, 215, 220);
    font-weight: 600;
    font-size: 19px;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
    background-color: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
    cursor:grabbing;
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 90%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    padding: 10px;
    border-radius: 25px;
    background-color: rgb(23, 35, 94);
    color: white;
  }
  
  .flip-card-back {
    padding: 20px;
    border-radius: 25px;
    background: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
    background-color: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
    color: white;
    transform: rotateY(180deg);
    text-align: left;
  }

.services-icon {
    width: 35%;
    margin-top: -6.45%;
}

.services-icon-outline {
    width: 35%;
    margin-top: -6.45%;
    margin-left: -35%;
    clip-path: polygon(0 95px, 100% 100px, 100% 100%, 0% 100%); /* Create a clip path to cut off the top part of the outline */
}

.content-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: relative;
    margin-top: -58.7%; /* to position it at the top of services-section background */
}

.services-content {
    margin-left: 35%;
    margin-top: 6%;
}

.services-title {
    font-weight: 600;
    font-family: 'HelveticaNeueLTStd-Bd';
    text-transform: uppercase;
    color: #000049;
    font-size: 50px;
    margin-left: -0.2vw;
}

.bar-icon {
    width: 7px;
    height: inherit;
    border-radius: 8px;
    margin-right: 10px;
}

.bar-text .blue-text {
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    font-family: 'HelveticaNeueLTStd-Md';
    color: rgba(62, 104, 189, 0.866);
    margin-left: 1.5vw;
    margin-top: 35px;
    text-align: left;
    height: inherit;
    width: 620px;
}

.grey-text {
    margin-top: 20px;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: rgba(95, 91, 93, 0.914);
    font-family: 'HelveticaLTStd-Roman';
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    width: 910px;
    margin-bottom: 2vh;
}

.grey-text2 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.grey-text3 {
    margin-bottom: 30px;
}

.vendors-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0%;
}

.vendor-logo .AWS-logo{
    position: absolute;
    display: block;
    transform: scale(1, 1);
    width: auto;
    height: 50px;
}

.vendor-logo .GC-logo {
    margin-left: -8%;
    position: absolute;
    display: block;
    transform: scale(1, 1);
    width: auto;
    height: 40px;
}

.vendor-logo .Oracle-logo {
    margin-left: -6%;
    position: absolute;
    display: block;
    transform: scale(1, 1);
    width: auto;
    height: 45px;
}

.vendor-logo .Azure-logo {
    margin-top: -5px;
    margin-left: -8%;
    position: absolute;
    display: block;
    transform: scale(1, 1);
    width: auto;
    height: 55px;
}

/* Service Offerings section */

.offerings-content {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    margin-left: 35%;
    width: 75%;
    height: 100%;
}

.offerings-title {
    width: 50%;
    font-weight: bold;
    text-transform: uppercase;
    color: #000049;
    font-size: 50px;
    font-weight: 600;
    font-family: 'HelveticaNeueLTStd-Bd';
    padding-bottom: 3vh;
    scroll-margin-top: 120px;
}

.offerings-table {
    width: 1285.58;
    height: auto;
    display: flex;
    flex-direction: row;
}

.list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5%;
    margin-left: -1vw;
}

.bar-text2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: relative;
    width: 100%;
}

.bar-text2 img {
    margin-right: 3.5%;
    width: 18px;
    height: 100%;
}

.bar-text2 p {
    display: flex;
    align-items: center;
    width: 75%;
    height: relative;
    margin: auto 0px; /* This for some reason centers the text to the images? */
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    font-family: 'HelveticaNeueLTStd-Md';
    color: rgba(62, 104, 189, 0.866);
    text-align: left;
    overflow: visible;
}

.left-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 5%;
    padding-top: 1%;
    width: 75%;
}

.right-list-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -15%;
    width: 100%;
    height: 100%;
}

.right-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    padding-top: 1%;
}

.img-p-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 25%;
    margin-top: 5%;
    width: 100%;
}

.img-p-left img {
    margin-right: 3%;
    width: 11%;
}

.img-p-left p {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: rgba(95, 91, 93, 0.914);
    font-family: 'HelveticaLTStd-Roman';
    overflow: visible;
    height: relative;
    margin: auto 0px;
    margin-top: 1vh;
    padding-left: 0.2vw;
}

.img-p-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: relative;
    margin-top: 10%;
    width: 100%;
}

.img-p-right img {
    margin-right: 3%;
    width: 13%;
}

.img-p-right p {
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: rgba(95, 91, 93, 0.914);
    font-family: 'HelveticaLTStd-Roman';
    overflow: visible;
    width: 200px;
    height: 100%;
    margin: auto 0px;
    margin-top: 1vh;
    padding-left: 0.2vw;
}

.bt-offerings {
    font-size: 20px;
    width: 75%;
}

/* Development Section */

.development-content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: relative;
}

.development-BG-gradient {
    background-image: linear-gradient(rgba(255, 0, 0, 0), rgba(17, 26, 65, 0.9), rgba(17, 26, 65, 1)), url('~/src/assets/images/background_dev.png');
    background-size: 100% 115%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.developmentOverlay {
    margin-left: 10%;
    margin-top: 5%;
    width: 80%;
    height: 90%;
}
  
.developmentTxtOverlay .dev-overlay {
    display: block;
    transform: scale(1, 1);
    max-width: 450px;
    height: auto;
    transform-origin: center center;
    margin-left: -0.3vw;
}
  
.DevParagraphs {
    margin-top: 3%;
    width: 100%;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    color: rgb(255, 255, 255); 
    z-index: 169;
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    text-align: left;
    padding: 0px;
    line-height: 26px;
}
  
.DevBox-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5%; 
    width: relative;
    height: relative;
}

.DevBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 47%;
    height: relative;
    opacity: 1;
    border-radius: 14px;
    background-color: rgb(12, 19, 52);
}

/* Development Icons */

.DevBox img {
    width: 20%;
    height: auto;
    margin-top: 6vh;
    z-index: 10;
}

.DevBox p {
    font-weight: 0;
    font-style: normal;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.918);
    z-index: 10;
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px;
    line-height: 29px;
    font-family: 'HelveticaNeueLTStd-Md';
    width: 85%;
    margin-top: 4%;
    margin-left: -1.5vw;
}

.DevBox .responsibilities {
    margin-top: 4%;
    margin-bottom: 2%;
    text-align: left;
    width: 95%;
    height: relative;
    margin-left: 5vh;
}
  
.DevBox .responsibilities h3 {
    font-family: 'HelveticaNeueLTStd-Md';
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #ffffff;
    padding-bottom: 1vh;
}
  
.DevBox .responsibilities ul {
    list-style-type: none; /* Remove default list bullets */
    text-align: left;
}
  
.DevBox .responsibilities li::before {
    content: "•"; /* Unicode character for a bullet */
    font-size: 17px; /* Adjust the size of the bullet */
    margin-right: 10px; /* some spacing between bullet and text */
    margin-left: -5%;
}

.DevBox .responsibilities li {
    font-family: 'HelveticaNeueLTStd-Md';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-align: left;
    color: rgba(211, 215, 220, 0.949);
    margin-bottom: 10px;
    line-height: 32px;
    font-style: normal;
    white-space: pre-wrap;
    display: flex;
    align-items: flex-start;
    padding-right: 1vw;
}

/* Migration Options Section */

.migrationOpts-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: relative;
    background-color: rgb(16, 25, 68);
}

/* Heading and paragraph adjustment */

.migration-text {
    width: 80%;
    margin-top: 4%;
    font-weight: 400;
    color: white;
    font-family: 'HelveticaNeueLTStd-Md';
}

.migration-text h2 {
    margin-bottom: 2.5%;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
}

.migOpts-para {
    margin-top: 1%;
    margin-left: 1.5%;
    color: rgb(211, 215, 220);
    font-size: 21px;
    width: 95%;
}

/* Adjust bar-text stats */

.migOpts {
    width: 90%;
}

.migOpts img {
    margin-right: 1%;
}

.migOpts p {
    color: white;
}

.maas-and-hybrid {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: relative;
    margin-top: 3%;
}

.content-box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    background-color: rgb(23, 35, 94);
    border-radius: 25px;
    padding: 20px;}

.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    height: relative;
    background-color: rgb(23, 35, 94);
    border-radius: 25px;
    padding: 20px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.content-box:hover {
    background: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
    color: white; /* Text color on hover */
}

.title {
    margin-top: 7%;
    margin-bottom: 2%;
    font-size: 55px;
    font-weight: 700;
    font-family: 'HelveticaNeueLTStd-Md';
}

.maas {
    background: linear-gradient(to right, rgb(85, 180, 241), rgb(54, 78, 170));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hybrid {
    background: linear-gradient(to right, rgb(85, 180, 241), rgb(54, 78, 170));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
}

.grey-bar {
    margin-top: 0vh;
    border: 1px solid #ffffff41;
    width: 32vw;
}

.content-box p {
    width: 93%;
    margin-top: 3%;
    text-align: center;
    color: rgb(211, 215, 220);
    font-weight: 600;
    font-size: 19px;
}

/* components section */

.components-content {
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.comp-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55%;
    margin-top: 1%;
    padding-bottom: 4%;
}

.comp-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 45%;
}

.components-content h3 {
    width: relative;
    padding-bottom: 0%;
    text-align: start;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    font-family: 'HelveticaNeueLTStd-Bd';
    color: white;
    margin-top: 0%;
    margin-bottom: 0%;
}

.img-text-comp {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 25%;
    margin-top: 30%;
    width: 100%;
}

.img-text-comp img {
    margin-right: 3%;
    width: 11%;
    margin-top: -10%;
}

.img-text-comp div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2%;
    margin-top: -10%;
    margin-bottom: 0%;
}

.img-text-comp p {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgb(211, 215, 220);
    font-family: 'HelveticaLTStd-Roman';
    overflow: visible;
    height: relative;
    margin-bottom: 0%;
    padding-bottom: 0%;
}

/* Comparison Section */

.comparison-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 90%;
    background-color: white;
}

.comparison-content h1 {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 1%;
    font-size: 50px;
    font-weight: 600;
    font-family: 'HelveticaNeueLTStd-Bd';
    text-transform: uppercase;
    color: #000049;
}

.comp-grey-line {
    display: flex;
    border: 1px solid #000000a8;
    width: 101%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.comp-header img {
    width: 7px;
    height: 60px;
    margin-top: 1vh;
    margin-right: 1%;
}

.comp-header h3 {
    color: rgb(24, 32, 81);
    font-size: 35px;
    font-weight: 600;
    font-family: 'HelveticaNeueLTStd-Bd';
}

.comp-header h2 {
    font-weight: 400;
    font-style: normal;
    font-family: 'HelveticaNeueLTStd-Md';
    font-size: 20px;
    color: rgb(69, 115, 207);
}

.full-table {
    margin-top: -66%;
}

.horizontal-division {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: relative;
    margin-top: 3%;
}

.horizontal-division p {
    width: 90%;
    height: relative;
    margin-left: 7.5%;
}

.leftmost-column {
    width: 23.3%;
    height: relative;
    font-size: 19px;
    font-weight: 20;
    font-family: 'HelveticaNeueLTStd-Bd';
    color: rgba(24, 32, 81, 0.797);
    padding-right: 2%;
}

.data-column {
    width: 37.5%;
    height: relative;
}

.data-column p {
    text-align: left;
    margin-left: 1%;
    width: 79%;
    font-weight: 100;
    font-style: normal;
    font-size: 17px;
    color: rgb(90, 91, 93);
    font-family: 'HelveticaNeueLTStd-Md';
    line-height: 27px;
}

.right-column {
    width: 37.5%;
    height: relative;
}

.grey-box {
    width: 40%;
    height: 120vh;
    align-self: flex-end;
    background-color: rgb(245, 245, 245);
    border-radius: 25px;
    z-index: 1;
}

.red-box {
    width: 91%;
    height: 220%;
    background-color: rgb(245, 245, 245);
    border-radius: 25px;
    margin: -5% 0% -3% -3%; /* margin: top% right% bottom% left% */
}

.blue-box {
    width: 91%;
    height: relative;
    background-color: rgb(62, 105, 189);
    border-radius: 25px;
    margin: -3% 0% -3% -3%; /* margin: top% right% bottom% left% */
}
.services-mobile {
    display: none;
}

@media (max-width: 1300px) {
    .flip-card-front {
        font-size: 12px;
        padding: 10px;
        border-radius: 25px;
        background-color: rgb(23, 35, 94);
        color: white;
      }
      
      .flip-card-back {
        font-size: 12px;
        padding: 20px;
        border-radius: 25px;
        background: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
        background-color: linear-gradient(to bottom, rgb(49, 190, 255), rgb(21, 35, 107)); /* Gradient background on hover */
        color: white;
        transform: rotateY(180deg);
        text-align: left;
      }
    .grey-box {
        display: none;
    }

    .services-mobile {
        display: flex;
        margin-left: 5%;
    }
    .services-content {
        display: none;
    }
    .offerings-content {
        display: none;
    }
    .services-mobile h1  {
        font-weight: bold;
        color: #000049;
        font-size: 45px;
        font-family: 'HelveticaNeueLTStd-Bd';
    }
    .services-icon {
        display: none;
    }

    .services-icon-outline {
        display: none;
    }
    .services-content {
        width: 380px;
        height: relative;
        background-color: #ffffff;
        margin-left: 2%;
       
    }
    .home-container {
        position: relative;
        margin-top: -8vh;
        top: 0;
        left: 0;
        width: 100%;
        height: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 0;
    }
    .services-section {
        /* width: 300px;
        margin-top: 5%;
        margin-left: 10%;
        scroll-margin-top: 90px; */
        position: relative;
        margin-top: 5%;
        top: 0;
        left: 0;
        width: 100%;
        height: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 0;
    }
    .content-stack {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        margin-top: 10%; /* to position it at the top of services-section background */
    }
    .developmentTxtOverlay .dev-overlay {
        display: block;
        transform: scale(1, 1);
        max-width: 300px;
        height: auto;
        transform-origin: center center;
        margin-left: -5%;
    }
    .DevBox p {
        font-weight: 0;
        font-style: normal;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.918);
        z-index: 10;
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        padding: 0px;
        line-height: 29px;
        font-family: 'HelveticaNeueLTStd-Md';
        width: 85%;
        margin-top: 4%;
        margin-left: -1.5vw;
    }
    .DevBox .responsibilities li {
        font-weight: 0;
        font-style: normal;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.918);
        z-index: 10;
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        padding: 0px;
        line-height: 29px;
        font-family: 'HelveticaNeueLTStd-Md';
        width: 85%;
        margin-top: 4%;
        margin-left: -1.5vw;
    }
    .title {
        margin-top: 7%;
        margin-bottom: 2%;
        font-size: 40px;
        font-weight: 700;
        font-family: 'HelveticaNeueLTStd-Md';
    }
    .content-box p {
        width: 93%;
        margin-top: 3%;
        text-align: center;
        color: rgb(211, 215, 220);
        font-weight: 600;
        font-size: 12px;
    }
    .grey-text {
        margin-top: 20px;
        font-size: 13px;
        font-weight: 200;
        color: rgba(95, 91, 93, 0.914);
        font-family: 'HelveticaLTStd-Roman';
        overflow: auto;
        /* white-space: pre-wrap; */
        text-align: left;
        width: 320px;
        margin-bottom: 2vh;
    }
    .comp-list {
        margin-left: -15%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 45%;
    }
    .img-text-comp p {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        color: rgb(211, 215, 220);
        font-family: 'HelveticaLTStd-Roman';
        overflow: visible;
        height: relative;
    }
    .img-text-comp {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        height: 30%;
        margin-top: 20%;
        width: 100%;
    }
    .bar-text p {
        margin-top: -5%;
        margin-left: 2%;
    }
    .bar-text .blue-text {
        font-weight: 200;
        font-style: normal;
        font-size: 13px;
        font-family: 'HelveticaNeueLTStd-Md';
        color: rgba(62, 104, 189, 0.866);
        text-align: left;
        height: inherit;
        width: 330px;
    }
    .bar-text img {
        display: none;
    }
    .vendors-logos {
        display: none;
    }
    .offerings-content {
        width: 380px;
        height: relative;
        background-color: #ffffff;
        margin-left: 2%;
       
    }
    .img-p-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 25%;
        margin-top: 30%;
        width: 100%;
    }
    .right-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: 100%;
        padding-top: 1%;
        margin-top: 30%;
    }
    .full-table {
        margin-top: 0%;
    }
}

@media (max-width: 1050px) {
    .migrationOpts-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        background-color: rgb(16, 25, 68);
        /* margin-top: 20%; */
    }
    .full-table {
        margin-top: 0%;
    }
    .grey-box {
        display: none;
    }
}

@media (max-width: 800px) {
    .migrationOpts-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        background-color: rgb(16, 25, 68);
        /* margin-top: 50%; */
    }
}

@media (max-width: 680px) {
    .migrationOpts-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        background-color: rgb(16, 25, 68);
        /* margin-top: 80%; */
    }
}

@media (max-width: 600px) {
    .migrationOpts-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        background-color: rgb(16, 25, 68);
        /* margin-top: 120%; */
    }
    /* .full-table {
        margin-top: -160%;
    } */
}

@media (max-width: 540px) {
    .migrationOpts-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        background-color: rgb(16, 25, 68);
        /* margin-top: 265%; */
    }

    .DevBox .responsibilities {
        margin-top: 4%;
        margin-bottom: 2%;
        text-align: left;
        width: 95%;
        height: relative;
        margin-left: -6%;
    }
    .components-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: relative;
        margin-bottom: 21%;
    }
    .leftmost-column h3{
        font-size: 15px;
    }
}
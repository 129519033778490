/* ./styles/CoffeeWithC1.css */
.coffee-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: -50px;
}

.coffee-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('~/src/assets/images/Background_coffee with cloud one.jpg');
    width: 100%;
    height: 100vh;
}

.coffee-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: -95%;
}

.coffee-title-image {
    margin-top: 20%;
}
.coffee-title-image img {
    width: 60%;
    height: auto;
}

.coffee-circle {
    scale: 35%;
    margin-top: -59%;
    margin-left: 65%;
}

.coffee-paragraph {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.coffee-paragraph h2{
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: rgb(105, 203, 255);
    font-family: "HelveticaNeueLTStd-Bd";
    white-space: pre-wrap;
    text-align: left;
    line-height: 12px;
    padding-top: 6%;
    padding-left: 0.2%;
}

.coffee-paragraph h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 34px;
    color: rgb(255, 255, 255);
    font-family: "HelveticaNeueLTStd-Bd";
    white-space: pre-wrap;
    text-align: left;
    line-height: 12px;
    padding-top: 1.3%;
    padding-left: 0.2%;
}

.coffee-paragraph .P1 {
    text-transform: none;
    width: 780px;
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    line-height: 36px;
    padding-top: 2%;
    padding-left: 0.2%;
}

.bar-text-combo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-top: 1%;
    margin-left: 0.4%;
    text-align: left;
}

.coffee-paragraph .bar1 img{
    width: 8px;
    height: 6.6vh;
}

.coffee-paragraph .bar2 img{
    width: 8px;
    height: 6.6vh;
}

.bar-text-combo .T1 .P1{
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    line-height: 25px;
    margin-top: -2.2%;
    margin-left: 1%;
}

.Bar2-Full{
    display: flex;
    flex-direction: row;
    margin-left: -45%;
}

.bar-text-combo .T2 .P2{
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    line-height: 25px;
    margin-top: -1%;
    margin-left: 6%;
}

.br1 {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    margin-left: 1.2%;
    margin-top: -5%;
}

.br2 {
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(211, 215, 220);
    font-family: "HelveticaLTStd-Roman";
    overflow: visible;
    white-space: pre-wrap;
    text-align: left;
    margin-left: 6.5%;
    margin-top: -25%;
}


@media (max-width: 1540px) {
    .coffee-title-image {
        margin-top: -3%;
    }
}

@media (max-width: 1440px) {
    .coffee-title-image {
        margin-top: -10%;
    }
}

@media (max-width: 1250px) {
    .coffee-title-image {
        margin-top: -40%;
    }
}

@media (max-width: 1110px) {
    .coffee-title-image {
        margin-top: -65%;
    }
}

@media (max-width: 930px) {
    .coffee-title-image {
        margin-top: 100%;
    }

    .coffee-circle {
       display: none;
    }

    .coffee-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;
        height: auto;
        margin-top: -35%;
        margin-left: 5%;
        font-size: 15px;
    }

    .coffee-paragraph {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        width: 380px;
    }
    .coffee-paragraph h3 {
        text-transform: uppercase;
        font-weight: 400;
        font-style: normal;
        font-size: 34px;
        color: rgb(255, 255, 255);
        font-family: "HelveticaNeueLTStd-Bd";
        white-space: pre-wrap;
        text-align: left;
        line-height: 40px;
        padding-top: 1.3%;
        padding-left: 0.2%;
        margin-bottom: 4%;
    }
    .coffee-paragraph .P1 {
        text-transform: none;
        width: 340px;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        color: rgb(211, 215, 220);
        font-family: "HelveticaLTStd-Roman";
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        line-height: 36px;
        padding-top: 2%;
        padding-left: 0.2%;
    }
    .bar-text-combo .T2 .P2 {
        text-transform: none;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(211, 215, 220);
        font-family: "HelveticaLTStd-Roman";
        overflow: visible;
        white-space: pre-wrap;
        text-align: left;
        line-height: 25px;
        margin-top: -1%;
        margin-left: 4%;
    }
    .coffee-container {
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position-y: -50px;
    }
    .coffee-background {
        height: 100%;
    }
}